export default {
    state: () => ({
        // modals
        isSidebarActive: false,
        isStoreySidebarActive:false,
        isOrderModalActive: false,
        order_type: {name:"callback"},
        stories: false,
    }),
    mutations: {
        SET_STORIES(state,data){
            state.stories = data
        },
        SET_ORDER_TYPE(state,data){
            state.order_type = data
        },
        SET_ORDER_MODAL: (state,data) => {
            state.isOrderModalActive = data
        },
        SET_SIDEBAR_STATE: (state, data) => {
            state.isSidebarActive = data;
            if (data) {
                if(state.isStoreySidebarActive){
                    document.body.classList.add("two-sidebar-opened");
                }
                document.body.classList.add("sidebar-opened");
            } else {
                if(state.isStoreySidebarActive == false){
                    document.body.classList.remove("sidebar-opened");
                }
                document.body.classList.remove("two-sidebar-opened");

            }
        },
        SET_INTERACTIVE_SIDEBAR_STATE: (state, data) => {
            state.isStoreySidebarActive = data;
            if (data) {
                if(state.isSidebarActive){
                    document.body.classList.add("two-sidebar-opened");
                }
                document.body.classList.add("sidebar-opened");
            } else {
                document.body.classList.remove("sidebar-opened");
                document.body.classList.remove("two-sidebar-opened");
            }
        },
    },
    actions: {
        OPEN_STORIES(ctx){
            document.body.classList.add("modal-open");
            document.body.classList.add("stories-open");
            ctx.commit('SET_STORIES', true)
        },
        CLOSE_STORIES(ctx){
            document.body.classList.remove("modal-open");
            document.body.classList.remove("stories-open");
            localStorage.setItem('stories','1')
            ctx.commit('SET_STORIES', false)
        },
        TOGGLE_ORDER_MODAL(ctx,value){
            ctx.commit('SET_ORDER_MODAL',value)
        },
        PUSH_ORDER_TYPE(ctx,value) {
            ctx.commit('SET_ORDER_TYPE',value)
        },
        async TOGGLE_SIDEBAR(ctx, id) {
            console.log('toggle_main',id)
            if (id) {
                if (ctx.state.isSidebarActive == id) {
                    ctx.commit("SET_SIDEBAR_STATE", false);
                    ctx.commit("SET_INSTANCE", null);
                } else {
                    await ctx.dispatch("GET_INSTANCE", id);
                    ctx.commit("SET_SIDEBAR_STATE", id);
                    if(window.RPTAnalytics){
                        // console.log(window.location.href)
                        // console.log(RPTAnalytics)
                        RPTAnalytics.event('showInstance', {id: id * 1})
                    }
                }
            } else {
                ctx.commit("SET_INSTANCE", null);
                ctx.commit("SET_SIDEBAR_STATE", false);
            }
        },
        async TOGGLE_INTERACTIVE_SIDEBAR(ctx, id) {
            // console.log('toggle_main',id)
            if (id) {
                ctx.commit("SET_INTERACTIVE_SIDEBAR_STATE", id);

            } else {
                ctx.commit("SET_INTERACTIVE_SIDEBAR_STATE", false);
            }
        },
        OPEN_ORDER_MODAL(ctx, data) {
            ctx.state.order_type = data;
            ctx.dispatch('TOGGLE_ORDER_MODAL',true)
        },
    },
    getters: {
        stories(state){
            return state.stories;
        },
        isOrderModalActive(state){
            return state.isOrderModalActive;
        },
        isSidebarActive(state) {
            return state.isSidebarActive;
        },
        isStoreySidebarActive(state) {
            return state.isStoreySidebarActive;
        },
        order_type(state) {
            return state.order_type;
        },
    }
}
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import i18n from "@/libs/i18n";
import router from "@/router";
import VueYandexMetrika from 'vue-yandex-metrika'
import VueGtm from '@gtm-support/vue2-gtm';
import langMixin from "@/router/langMixin";
import interactive from '@/store/interactive'
import filters from '@/store/filters'
import favorites from '@/store/favorites'
import models from '@/store/models'
import modals from '@/store/modals'
Vue.use(Vuex);
function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
function setCookie(name, value, options = {}) {
  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }
  document.cookie = updatedCookie;
}

export default new Vuex.Store({
  strict: process.env.DEV,
  modules:{
    interactive,
    filters,
    favorites,
    models,
    modals,
  },
  state: {
    //main
    admin: false,
    crm: null,
    utm: null,
    tracking_data: null,
    ref: null,
    loadList: [],
    isLoaded: false,
    isBootLoaded: false,
    // bootLoadingList: ['',''],


    base_url: "https://admin.realtyprotech.com/api/v1/",
    // base_url: 'http://127.0.0.1:8000/api/v1/',
    // base_url: 'https://rptadmin.dynamicov.com/api/v1/',
    info: null,
    main_promoaction: null,
    header_name: null,

    // from token
    token: null,
    projectToken: null,
    id: null, // project id
    group_id: null,
    main_page: "Info",
    tokenSettings: null,
    currency: null,
    area_unit:null,
    lang: null,
    logo: null,
    show_square_meter: false,
    allowed_langs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    rtl_langs: [2],

  },
  getters: {
    isAdmin(state){
      return state.admin
    },
    loadList(state){
      return state.loadList
    },
    show_square_meter(state){
      return state.show_square_meter
    },
    info(state){
      return state.info;
    },
    main_page(state){
      return state.main_page;
    },
    crm(state){
      return state.crm;
    },
    ref(state){
      return state.ref;
    },
    token(state) {
      return state.token;
    },
    projectToken(state){
      return state.projectToken;
    },
    tokenSettings(state) {
      return state.tokenSettings;
    },
    currency(state){
      return state.currency
    },
    area_unit(state){
      return state.area_unit
    },
    base_url(state) {
      return state.base_url;
    },
    after_estate_select(state) {
      return state.tokenSettings.interactive.after_estate_select;
    },
    after_estate_select_mobile(state) {
      return state.tokenSettings.interactive.after_estate_select_mobile;
    },

    logo(state){
      return state.logo;
    },
    isLoaded(state) {
      return state.isLoaded && state.loadList.length === 0;
    },
    isBootLoaded(state) {
      return state.isBootLoaded
    },


    isRtlLang(state) {
      return state.lang?.is_rtl;
    },
    // instance: (state) => (id) => {
    //   return state.instances?.find(instance => instance.id === id)
    // },


    group_id(state){
      return state.group_id;
    },
    project_id(state) {
      return state.id;
    },

    header_name(state){
      return state.header_name;
      // return state.id ? state?.project?.name : state?.projectGroup?.name;
    },

    main_promoaction(state){
      return state.main_promoaction
    },
    utm(state){
      return state.utm
    },

    tracking_data(state){
      let arr = {};
      
      if(state.tokenSettings?.yandex_metrika != null){
        const ycounter = getCookie('_ym_counter');
        if(ycounter && ycounter == state.tokenSettings?.yandex_metrika){
          arr['_ym_counter'] = state.tokenSettings?.yandex_metrika;
          if(getCookie('_ym_uid')){
            arr['_ym_uid'] = getCookie('_ym_uid');
          }
          if(state.tracking_data != null){
            if(state.tracking_data.yclid){
              arr['yclid'] = state.tracking_data.yclid;
            }
          }
        }
      }
      if(state.tracking_data != null){
        if(state.tracking_data.referrer){
          arr['referrer'] = state.tracking_data.referrer;
        }
        if(state.tracking_data.gclid){
          arr['gclid'] = state.tracking_data.gclid;
        }
      }
      if(getCookie('roistat_visit')){
        arr['roistat'] = getCookie('roistat_visit');
      }

      if(window.RPTAnalytics){
        arr['RPTAnalytics_uid'] = window.RPTAnalytics.getUid();
      }


      return arr;
    },
    lang(state){
      return state.lang
    },

  },
  mutations: {
    // SET_CHESS_TO_STATE:(state, response ) => {
    //   state.chess = response;
    // },
    SET_CRM:(state,crm) =>{
      state.admin = true;
      state.crm = crm
    },
    SET_REF:(state,ref) => {
      state.ref = ref
    },
    SET_LOAD_END: (state) => {
      state.isLoaded = true;
    },
    SET_LOAD_START: (state) => {
      state.isLoaded = false;
    },
    SET_BOOT_LOADING: (state, response) => {
      state.isBootLoaded = response;
    },
    LOAD_LIST_ADD: (state, response) => {
      state.loadList.push(response);
    },
    LOAD_LIST_REMOVE: (state, response) => {
      state.loadList.splice(state.loadList.indexOf(response), 1);
    },
    SET_ID_TO_STATE: (state, response) => {
      state.id = response;
    },
    SET_GROUP_ID_TO_STATE: (state, response) => {
      state.group_id = response;
    },
    SET_NAME_TO_STATE: (state, response) => {
      state.name = response;
    },
    SET_MAIN_PROMOACTION_TO_STATE: (state, response) => {
      state.main_promoaction = response;
    },
    SET_LANG_TO_STATE: (state, response) => {
      state.lang = response;
    },
    SET_CURRENCY: (state, response) => {
      state.currency = response;
    },
    SET_AREA_UNIT: (state, response) => {
      state.area_unit = response;
    },
    SET_LANGS_TO_STATE: (state,response) => {
      state.allowed_langs = response;
    },
    SET_SHOW_SQUARE_METER:(state,response)=>{
      state.show_square_meter = response;
    },
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_PROJECT_TOKEN(state, data) {
      state.projectToken = data;
    },
    SET_TOKEN_SETTINGS(state, data) {
      let resetRPTAnalytics = (data.rpt_tracker_id != state?.tokenSettings?.rpt_tracker_id)
        || (data.analytics.aid != state?.tokenSettings?.analytics?.aid)
        || (data.analytics.uid != state?.tokenSettings?.analytics?.uid);
      // let resetRPTAnalytics = true;

      state.tokenSettings = data;
      state.logo = data.logo;
      state.interactiveTranslate = data.interactive_translate !=null ? data.interactive_translate : 0

      if(state.tokenSettings.yandex_metrika != null) {
        Vue.use(VueYandexMetrika, {
          id: state.tokenSettings.yandex_metrika,
          debug: true,
          env: 'production',
          options: {
            webvisor: true
          }
        })
      }
      if(state.tokenSettings.gtm_id){
        Vue.use(VueGtm, {
          id: state.tokenSettings.gtm_id,
          defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
          compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
          nonce: '2726c7f26c', // Will add `nonce` to the script tag
          enabled: true,
          debug: true, // Whether or not display console logs debugs (optional)
          loadScript: true, 
          vueRouter: router, 
          trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
        });
      }
     
    
      if(window.RPTAnalytics && resetRPTAnalytics){
        window.RPTAnalytics.destroy();
      }

      if(state.tokenSettings.rpt_tracker_id != null && !state.crm){

        let options = {
          sessionType : 'public module',
          userType : state.tokenSettings.analytics.utp,
          userId : state.tokenSettings.analytics.uid,
          addonId : state.tokenSettings.analytics.aid
        };

        (function() {
          var analyticsScript = document.createElement('script');
          analyticsScript.src = 'https://admin.realtyprotech.com/services/analytics/script.js?v=1.2';
          analyticsScript.addEventListener('load', function() {
              RPTAnalytics.init(state.tokenSettings.rpt_tracker_id, {}, options).then(()=>{
              });
          });
          // Append the script element to the document
          document.head.appendChild(analyticsScript);
        })();
      }
    },
    SET_HEADER_NAME(state,data){
      state.header_name = data
    },
    SET_MAIN_PAGE(state,data) {
      state.main_page = data
    },
    SET_INFO(state,data) {
      state.info = data
    },

    SET_UTM(state,data){
      state.utm = data
    },
    SET_TRACKING_DATA(state, data){
      state.tracking_data = data;
    },

  },
  actions: {
    async axiosWrapped(ctx,data) {
      let params = {};
      if(data.params){
        params = data.params
      }
      params.token = ctx.state.token;

      data.url = ctx.state.base_url + data.url;
      data.lang = ctx.state.lang?.id;
      
      if(ctx.state.projectToken){
        params.project_token = ctx.state.projectToken
      }

      if (data.lang) {
        return axios.get(`${data.url}`,
            {
              params,
              headers: { "X-Accept-Language": `${data.lang}` },
            });
      } else {
        return axios.get(`${data.url}`, {params});
      }
    },
    UPDATE_HEADER_NAME(ctx,value) {
      ctx.commit('SET_HEADER_NAME',value)
    },
    GET_REF(ctx,ref){
      ctx.commit("SET_REF", ref);
    },
    GET_CRM(ctx,crm){
      console.log('CRM set in store')
      ctx.commit("SET_CRM", crm);
    },
    CHANGE_BOOT_LOADING(ctx,response){
      ctx.commit('SET_BOOT_LOADING',response)
    },
    GET_UTM(ctx,data){
      let cookie_key = 'utm';
      if(ctx.state.group_id != null){
        cookie_key = 'utm_gid_'+ctx.state.group_id;
      } else if (ctx.state.id != null){
        cookie_key = 'utm_pid_'+ctx.state.id;
      }
      let utm = getCookie(cookie_key)
      if(utm){
        ctx.commit("SET_UTM",JSON.parse(utm));
      } else {
        if(data.utm_source || data.utm_campaign || data.utm_content || data.utm_medium || data.utm_term){
          setCookie(cookie_key, JSON.stringify(data), {secure: true, 'max-age': 604800})
          ctx.commit("SET_UTM",data);
        }
      }
      // console.log('utm',ctx.state.utm);
    },
    GET_TRACKING_DATA(ctx,data){
      let cookie_key = 'tracking_data';
      if(ctx.state.group_id != null){
        cookie_key = 'tracking_data_gid_'+ctx.state.group_id;
      } else if (ctx.state.id != null){
        cookie_key = 'tracking_data_pid_'+ctx.state.id;
      }
      let tracking_data = getCookie(cookie_key)

      if(tracking_data){
        ctx.commit("SET_TRACKING_DATA",JSON.parse(tracking_data));
      } else {
        if(data.yclid || data.gclid || data.referrer){
          setCookie(cookie_key, JSON.stringify(data), {secure: true, 'max-age': 604800})
          ctx.commit("SET_TRACKING_DATA",data);
        }
      }
    },

    async OPEN_PROJECT(ctx, data) {
      ctx.commit('SET_LOAD_START')
      await ctx.dispatch('GET_TOKEN',{ token: ctx.state.token, projectToken: data.token })

      langMixin.methods.push({
        name: data.page ? data.page : ctx.state.main_page,
        params: data.params ? data.params : {}
      })
    },
    async GET_TOKEN(ctx, tokens) {
      ctx.dispatch('LOAD_START','GET_TOKEN')
      let url = 'public/token';
      ctx.commit("SET_TOKEN", tokens.token);
      ctx.commit("SET_PROJECT_TOKEN", tokens.projectToken);
      await ctx.dispatch('axiosWrapped',{url})
        .then(async function (response) {
          const data = response.data.data
          // try {
          //   this.currentTheme = localStorage.getItem('theme');
          // } catch (e) {
          //   this.currentTheme = 'light';
          // }

          try {
            if (!localStorage.getItem('theme')) {
                Vue.prototype.$changeTheme(data.color_scheme.theme);
            }
          } catch (e) {
              Vue.prototype.$changeTheme(data.color_scheme.theme);
          }

          document.documentElement.style.setProperty('--primary', data.color_scheme.primary);
          document.documentElement.style.setProperty('--primary-active', data.color_scheme.primary_active);
          document.documentElement.style.setProperty('--primary-shadow', data.color_scheme.primary_shadow);
          ctx.commit("SET_TOKEN_SETTINGS", data);

          if(data.type == 'App\\Models\\Project'){
           ctx.commit("SET_ID_TO_STATE", data.id);
            if(data.show_projects){
              ctx.commit("SET_GROUP_ID_TO_STATE", data.group_id);
            }
            // await ctx.commit("SET_LANG_TO_STATE", data.lang);
          }
          else {
            ctx.commit("SET_GROUP_ID_TO_STATE", data.id);
          }
          ctx.commit("SET_MAIN_PAGE",data.main_page)
          ctx.commit("SET_INFO",data.info)
          ctx.commit("SET_SHOW_SQUARE_METER",data.show_square_meter)
          let currentRoute = router.currentRoute;
          let lang = data.lang;
          if(currentRoute.params.lang){
            let foundObject = data.allowed_langs.find((lang) => lang.name === currentRoute.params.lang);
            if(foundObject){
              lang = foundObject;
            }
          }
          await ctx.dispatch("CHANGE_LANG", lang)
          ctx.commit("SET_LANGS_TO_STATE", data.allowed_langs)
          ctx.commit("INIT_FAVORITES");
          ctx.commit("SET_LOAD_END");
      })
        .catch(error => {
          console.log('error in get token',error)
          langMixin.methods.push({name:'error-404'})
          // router.push({name:'error-404'})
        });
      console.log("get token finish");
      ctx.dispatch('LOAD_END','GET_TOKEN')
    },

    LOAD_START(ctx,item){
      ctx.commit("LOAD_LIST_ADD",item)
    },
    LOAD_END(ctx,item){
      ctx.commit("LOAD_LIST_REMOVE",item)
    },

    async CHANGE_LANG(ctx, lang) {

      let bootstrapCssCdn;
      i18n.locale = lang.name
      ctx.dispatch('LOAD_START','CHANGE_LANG')
      if (lang.is_rtl) {
        bootstrapCssCdn =
          "https://fastly.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css";
        document.documentElement.setAttribute("dir", "rtl");
        document.documentElement.setAttribute("lang", lang.name);
      }
      else {
        bootstrapCssCdn =
          "https://fastly.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.setAttribute("lang", lang.name);
      }
      document.getElementById("bootstrap_css").href = bootstrapCssCdn;
      ctx.commit("SET_LANG_TO_STATE", lang);
      if(ctx.state.lang != null){
        let currentRoute = router.currentRoute;
        let  updatedParams = { ...currentRoute.params };
        delete updatedParams.preserveLang;
        if (lang.id === ctx.state.tokenSettings.lang.id) {
          delete updatedParams.lang;
          updatedParams.preserveLang = true;
        } else {
          updatedParams.lang = lang.name;
        }

        let newRoute = {
          name: currentRoute.name,
          params: updatedParams,
          query: currentRoute.query,
          hash: currentRoute.hash,
          replace: true,
        };

        // router.push(newRoute);
        if(currentRoute.params.lang != updatedParams.lang) {
          langMixin.methods.push(newRoute)
          if(currentRoute.name =='InteractiveWithGroup'){
            ctx.commit("SET_TOOLTIP_INFO", null);
            ctx.dispatch('GET_PROJECT_GROUP_INTERACTIVE')
          }
        }
      }

      ctx.commit("SET_TOOLTIP_INFO", null);

      if(ctx.state.tokenSettings.type === 'App\\Models\\Project'){
        ctx.dispatch("GET_PROJECT", ctx.state.id)
            .then(()=>{
              ctx.dispatch("UPDATE_HEADER_NAME",ctx.getters.project?.name)
              ctx.dispatch("GET_CHESS", ctx.state.id);
            });
      }

      if(ctx.state.group_id != null || ctx.state.tokenSettings.show_project){
        ctx.dispatch("GET_PROJECT_GROUP", ctx.state.group_id).then(()=>{
          if(ctx.state.tokenSettings.type !== 'App\\Models\\Project'){
            ctx.dispatch("UPDATE_HEADER_NAME",ctx.getters.projectGroup?.name)
            ctx.dispatch("CHANGE_BOOT_LOADING",true)
          }
        })
      }
      // console.log(ctx.getters.isSidebarActive)
      if(ctx.getters.isSidebarActive){
        // console.log(ctx.getters.isSidebarActive)
        ctx.dispatch("GET_INSTANCE", ctx.getters.isSidebarActive).then(()=>{
          ctx.commit("SET_SIDEBAR_STATE", ctx.getters.isSidebarActive);
        });
      }
      ctx.dispatch('LOAD_END','CHANGE_LANG')
    },

  },
});

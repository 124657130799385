import i18n from "@/libs/i18n";
import _ from "lodash";
const hasNonEmptyObjects = (obj) => {
  return Object.keys(obj).some((key) => {
    return obj[key] && Object.keys(obj[key]).length > 0;
  });
};
function calculateSimilarity(instance1, instance2) {
  const weights = {
    tags: 20, // Custom weight for tags
    price: 15, // Custom weight for price
    area_total: 15, // Custom weight for area_total
    rooms: 30, // Custom weight for rooms
    storey: 20, // Custom weight for storey
  };

  let similarity = 0;
  let data = {};

  // Check tags similarity
  const sharedTags = instance1.tags?.filter((tag1) =>
    instance2?.tags?.some((tag2) => tag1?.id === tag2?.id)
  );
  if (instance1.tags?.length) {
    similarity += (sharedTags.length / instance1.tags?.length) * weights.tags;
  }
  data.tags = similarity;

  let arg;
  // Check price similarity
  // console.log(instance2);
  if (instance1.price !== null && instance2.price !== null) {
    arg = Math.ceil(Math.abs(100 - (instance2.price / instance1.price) * 100));
    if (arg == 0) {
      similarity += weights.price;
      data.price = weights.price;
    } else {
      similarity += weights.price / (1 + arg / 100);
      data.price = weights.price / (1 + arg / 100);
    }
  }

  // Check layout.area_total similarity
  if (instance1?.layout?.area_total && instance2?.layout?.area_total) {
    arg = Math.ceil(
      Math.abs(
        100 - (instance2.layout.area_total / instance1.layout.area_total) * 100
      )
    );
    if (arg == 0) {
      similarity += weights.area_total;
      data.areaTotal = weights.area_total;
    } else {
      data.areaTotal = weights.area_total / (1 + arg / 100);
      similarity += weights.area_total / (1 + arg / 100);
    }
  }
  //
  // // Check layout.rooms similarity
  if (instance1?.layout?.label === instance2?.layout?.label) {
    similarity += weights.rooms;
    data.rooms = weights.rooms;
  }
  //
  // console.log(instance1)
  // console.log(instance2)
  // Check storey similarity
  // console.log(instance1.storey.number, instance2.storey)
  arg = Math.abs(instance1.storey.order - instance2.storey.order);
  similarity += weights.storey - 0.05 * weights.storey * arg;
  data.storey = weights.storey - 0.05 * weights.storey * arg;

  return [similarity, data];
}

export default {
  state: () => ({
    project: null,
    instance: null,
    album: null,
    layouts: null,
    projectGroup: null,
    instances: null,
    storeys: [],
    sections: [],
    estates: [],
    statuses: null,
    tags: null,
    promoactions: null,
  }),
  getters: {
    promoactions(state) {
      return state.promoactions;
    },
    free_instances(state) {
      if (state.instances) {
        return state.instances.filter((instance) => instance.status.type == 1);
      } else return [];
    },
    GET_SIMILAR_INSTANCES: (state, getters) => (instance) => {
      let instances = getters.free_instances.filter((i) => i.id != instance.id);
      let similarities = instances.map((instance2) => {
        let similarity = calculateSimilarity(instance, instance2);
        return [similarity, instance2];
      });
      similarities.sort((a, b) => b[0][0] - a[0][0]);
      similarities = similarities.slice(0, 3);
      return similarities.map((item) => item);

      // теги -
      // комнатность
      // цена за метр квадратный
      // этаж
    },
    layouts(state) {
      return state.layouts;
    },
    types(state) {
      let arr = [];
      state?.layouts?.forEach((layout) => {
        if (!arr.includes(layout.type)) {
          arr.push(layout.type);
        }
      });
      return arr;
    },

    statuses(state) {
      return state.statuses;
    },
    tags(state) {
      return state.tags;
    },
    areaMaxValue(state) {
      let max = 0;
      state?.layouts?.forEach((layout) => {
        if (max < layout.area_total * 1) {
          max = layout.area_total * 1;
        }
      });
      return Math.ceil(max);
    },
    areaMinValue(state) {
      let min = Infinity;
      state?.layouts?.forEach((layout) => {
        if (min > layout.area_total * 1) {
          min = layout.area_total * 1;
        }
      });
      if (min == Infinity) {
        min = 0;
      }
      return Math.floor(min);
    },
    priceMaxValue(state) {
      let max = 0;
      state?.instances?.forEach((instance) => {
        if (max < instance.price) {
          max = instance.price;
        }
      });
      if (max == 0) {
        max = Infinity;
      }
      return max;
    },
    priceMinValue(state) {
      let min = Infinity;
      state?.estates?.forEach((estate) => {
        if (min > estate.min_price) {
          min = estate.min_price;
        }
      });
      if (min == Infinity) {
        min = 0;
      }
      return min;
    },
    notHaveChilds: (state) => (id, view) => {
      switch (view) {
        case "project":
          return false;
        case "estate":
          let estate = state.estates?.find((estate) => estate.id === id);
          if (estate?.inactive) {
            return true;
          }
          return !estate?.free_count;
        case "section":
          return !state.sections?.find((section) => section.id === id)
            ?.free_count;
        case "storey":
          return !state.storeys?.find((storey) => storey.id === id)?.free_count;
        case "instance":
          return !(
            state.instances?.find((estate) => estate.id === id)?.status.type ===
            1
          );
      }
      return true;
    },
    instance(state) {
      return state.instance;
    },
    album(state) {
      return state.album;
    },
    instances(state) {
      return state.instances;
    },
    projects(state) {
      return state?.projectGroup?.projects;
    },
    projectGroup(state) {
      return state.projectGroup;
    },
    project(state) {
      return state.project;
    },
    projectType(state) {
      return state?.project.type;
    },
    estates(state) {
      return state.estates;
    },
    sections(state) {
      return state.sections;
    },
    storeys(state) {
      return state.storeys;
    },
    storeysNumbers(state) {
      let maxSection = null;
      state.estates?.forEach((estate) => {
        estate.sections.forEach((section) => {
          if (
            !maxSection ||
            section.storeys.length > maxSection.storeys.length
          ) {
            maxSection = JSON.parse(JSON.stringify(section));
          }
        });
      });
      let maxSectionStoreys = maxSection ? maxSection.storeys : [];
      return maxSectionStoreys.map((storey) => storey.number).reverse();
    },
    name_by_id: (state) => (id, type) => {
      switch (type) {
        case "estateId":
          return state.estates.find((item) => item.id == id)?.name;
        case "sectionId":
          // return 'sect'
          return (
            i18n.t("titles.Section") +
            " " +
            state.sections.find((item) => item.id == id)?.number
          );
        case "storeyId":
          return (
            i18n.t("titles.Storey") +
            " " +
            state.storeys.find((item) => item.id == id)?.number
          );
      }
    },
  },
  mutations: {
    SET_PROJECT_GROUP: (state, response) => {
      state.projectGroup = response;
    },
    SET_PROJECT_TO_STATE: (state, response) => {
      state.project = response;
    },
    SET_PROMOACTIONS_TO_STATE: (state, response) => {
      state.promoactions = response;
    },
    SET_STATUSES_TO_STATE: (state, response) => {
      state.statuses = response;
    },
    SET_TAGS_TO_STATE: (state, response) => {
      state.tags = response;
    },
    SET_LAYOUTS_TO_STATE: (state, response) => {
      state.layouts = response;
    },
    SET_ESTATES_TO_STATE: (state, response) => {
      state.estates = response;
    },
    SET_INSTANCE: (state, response) => {
      state.instance = response;
    },
    SET_ALBUM: (state, response) => {
      state.album = response;
    },
    SET_INSTANCES: (state, response) => {
      state.instances = response;
    },
    SET_STOREYS: (state, response) => {
      state.storeys = response;
    },
    SET_SECTIONS: (state, response) => {
      state.sections = response;
    },
  },
  actions: {
    async GET_PROJECT_GROUP(ctx, id = 0) {
      ctx.dispatch("LOAD_START", "GET_PROJECT_GROUP");
      await ctx
        .dispatch("axiosWrapped", {
          url: `public/project-groups/${id}?a=${ctx.rootState.admin}`,
        })
        .then(function (response) {
          const group = response.data.data;
          document.title = group.name;
          ctx.commit("SET_PROJECT_GROUP", group);
          console.log("get project group finish");
        })
        .catch(function (error) {
          console.log(error, "error in fetching project group");
        })
        .finally(() => {
          ctx.dispatch("LOAD_END", "GET_PROJECT_GROUP");
        });
    },
    async GET_PROJECT(ctx, id = 0) {
      ctx.dispatch("LOAD_START", "GET_PROJECT");
      await ctx
        .dispatch("axiosWrapped", {
          url: `public/projects/${id}?a=${ctx.rootState.admin}`,
        })
        .then(function (response) {
          const projects = response.data;
          document.title = projects.data.project.name;
          ctx.commit("SET_CURRENCY", projects.data.project.currency);
          ctx.commit("SET_AREA_UNIT", projects.data.project.area_unit);
          ctx.commit("SET_PROJECT_TO_STATE", projects.data.project);
          ctx.commit(
            "SET_MAIN_PROMOACTION_TO_STATE",
            projects.data.main_promoaction
          );
          ctx.commit("SET_LAYOUTS_TO_STATE", projects.data.layouts);
          ctx.commit("SET_PROMOACTIONS_TO_STATE", projects.data.promoactions);
          ctx.commit("SET_STATUSES_TO_STATE", projects.data.statuses);
          ctx.commit("SET_TAGS_TO_STATE", projects.data.tags);
          console.log("get project finish");
        })
        .catch(function (error) {
          console.log(error, "error in fetching project");
        })
        .finally(() => {
          ctx.dispatch("LOAD_END", "GET_PROJECT");
        });
    },
    async GET_CHESS(ctx, id) {
      ctx.dispatch("LOAD_START", "GET_CHESS");
      await ctx
        .dispatch("axiosWrapped", {
          url: `public/projects/${id}/chess?a=${ctx.rootState.admin}`,
        })
        .then(async function (response) {
          let projects = response.data;
          await ctx.commit("SET_ESTATES_TO_STATE", projects.data);
          ctx.dispatch("PROCESS_CHESS", projects.data);
          console.log("get chess finish");
        })
        .catch(function (error) {
          console.log(error, "error in fetching chess");
          ctx.dispatch("LOAD_END", "GET_CHESS");
        })
        .finally(() => {
          ctx.dispatch("CHANGE_BOOT_LOADING", true);
        });
    },
    PROCESS_CHESS(ctx, response) {
      let instances = [];
      let filter = [];
      let sections = [];
      let storeys = [];
      response?.forEach((estate) => {
        estate.min_price = Infinity;
        estate.free_count = 0;
        estate.free_counts = {};
        estate.sections?.forEach((section) => {
          section.min_price = Infinity;
          section.free_count = 0;
          section.free_counts = {};
          // section.storeys.sort((a,b) => {a.order - b.order})
          section.storeys?.forEach((storey) => {
            storey.min_price = Infinity;
            storey.free_count = 0;
            storey.free_counts = {};
            storey.instances.forEach((instance) => {
              instance.layout = ctx.state.layouts.find(
                (item) => item.id == instance.layout
              ); // layout
              instance.inactive = estate.inactive;

              if (instance.status == null) {
                instance.status =
                  ctx.state.statuses[ctx.state.statuses.length - 1];
              } else {
                instance.status = ctx.state.statuses.find(
                  (item) => item.id == instance.status.id
                );
                if (instance.status == null) {
                  instance.status =
                    ctx.state.statuses[ctx.state.statuses.length - 1];
                }
              }
              // console.log(instance.promoactions)
              // instance.promoactions = instance?.promoactions?.map((item) => {
              //     // promo
              //     const promoaction = ctx.state.promoactions?.find((promo) => promo.id === item);
              //     return promoaction ? promoaction : null;
              // }).filter(item => item !== null);

              instance.tags = instance?.tags?.map((item) => {
                // tags
                return ctx.state.tags.find((tag) => tag.id === item);
              });
              instance.storey = { number: storey.number, order: storey.order };
              // todo ordering
              instance.estate = {
                id: estate.id,
                name: estate.name,
                type: estate.type,
              };

              if (instance.promoactions.length && instance.price != null) {
                let min = 0;
                instance.promoactions.forEach((item) => {
                  if (item && item.hasOwnProperty("sale") && item.sale > min) {
                    min = item.sale;
                  }
                });
                instance.old_price = instance.price;
                instance.price = instance.price - (instance.price * min) / 100;
              }
              if (instance.price && instance?.layout?.area_total) {
                instance.price_m2 =
                  instance.price / instance?.layout?.area_total;
              }
              if (instance.status.type == 1) {
                storey.free_count++;
                if (storey.min_price > instance.price) {
                  storey.min_price = instance.price
                    ? instance.price
                    : storey.min_price;
                }
                const safeKey =
                  instance.layout?.label === null
                    ? "null"
                    : instance.layout?.label;
                if (!storey.free_counts[safeKey]) {
                  storey.free_counts[safeKey] = {
                    count: 1,
                    min_price:
                      instance.price != null ? instance.price : Infinity,
                  };
                } else {
                  storey.free_counts[safeKey].count++;
                  if (storey.free_counts[safeKey].min_price > instance.price) {
                    storey.free_counts[safeKey].min_price =
                      instance.price != null
                        ? instance.price
                        : storey.free_counts[safeKey].min_price;
                  }
                }
              }
              instances.push(instance);
              filter.push(instance.id);
            });
            if (section.min_price > storey.min_price) {
              section.min_price = storey.min_price;
            }
            section.free_count += storey.free_count;
            if (section.free_counts === undefined) {
              section.free_counts = _.cloneDeep(storey.free_counts);
            } else {
              Object.keys(storey.free_counts).forEach(function (key) {
                if (section.free_counts[key]) {
                  section.free_counts[key].count +=
                    storey.free_counts[key].count;
                  if (
                    section.free_counts[key].min_price >
                    storey.free_counts[key].min_price
                  ) {
                    section.free_counts[key].min_price =
                      storey.free_counts[key].min_price;
                  }
                } else {
                  section.free_counts[key] = { ...storey.free_counts[key] };
                }
              });
            }
            storeys.push(storey);
          });
          if (estate.min_price > section.min_price) {
            estate.min_price = section.min_price;
          }

          estate.free_count += section.free_count;

          if (hasNonEmptyObjects(estate.free_count)) {
            estate.free_counts = _.cloneDeep(section.free_counts);
          } else {
            Object.keys(section.free_counts).forEach(function (key) {
              if (estate.free_counts[key]) {
                estate.free_counts[key].count += section.free_counts[key].count;
                if (
                  estate.free_counts[key].min_price >
                  section.free_counts[key].min_price
                ) {
                  estate.free_counts[key].min_price =
                    section.free_counts[key].min_price;
                }
              } else {
                estate.free_counts[key] = { ...section.free_counts[key] };
              }
            });
          }

          sections.push(section);
        });
        if (estate.inactive) {
          estate.free_count = 0;
        }
      });
      ctx.commit("SET_STOREYS", storeys);
      ctx.commit("SET_SECTIONS", sections);
      ctx.commit("SET_FILTER", filter);
      ctx.commit("SET_INSTANCES", instances);
      console.log("set instances finish");
      ctx.dispatch("LOAD_END", "GET_CHESS");
    },
    RESET_ALBUM(ctx) {
      ctx.commit("SET_ALBUM", null);
    },
    async GET_ALBUM(ctx, id) {
      // ctx.dispatch('LOAD_START','GET_INSTANCE')
      await ctx
        .dispatch("axiosWrapped", {
          url: `public/construction-progress/${id}?a=${ctx.rootState.admin}`,
        })
        .then(function (response) {
          let album = response.data.data;
          let allItems = [];
          album.album.forEach((elem) => {
            elem.items.forEach((item) => {
              item.estate = ctx.state.estates.find(
                (estate) => estate.id == item.estate_id
              ).name;
            });
            allItems.push(...elem.items);
          });
          album.album = allItems;
          // console.log(album);
          ctx.commit("SET_ALBUM", album);
        })
        .catch(function (error) {
          console.log(error, "error in fetching album");
        })
        .finally(function () {
          // ctx.dispatch('LOAD_END','GET_INSTANCE')
        });
    },
    async GET_INSTANCE(ctx, id) {
      ctx.dispatch("LOAD_START", "GET_INSTANCE");
      await ctx
        .dispatch("axiosWrapped", {
          url: `public/instances/${id}?a=${ctx.rootState.admin}`,
        })
        .then(function (response) {
          ctx.commit("SET_INSTANCE", response.data.data);
        })
        .catch(function (error) {
          console.log(error, "error in fetching instance");
        })
        .finally(function () {
          ctx.dispatch("LOAD_END", "GET_INSTANCE");
        });
    },
  },
};

import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
const PageInteractive = () => import(/* webpackChunkName: "Interactive" */ "@/views/PageInteractive");
const PagePremises = () => import(/* webpackChunkName: "Premises" */"@/views/PagePremises");
const PageChess = () => import(/* webpackChunkName: "Chess" */"@/views/PageChess");
const PageInfo = () => import(/* webpackChunkName: "Info" */"@/views/PageInfo");
const PageProjects = () => import(/* webpackChunkName: "Info" */"@/views/PageProjects");
const PagePanorama = () => import(/* webpackChunkName: "Panorama" */"@/views/PagePanorama");
const Main = () => import(/* webpackChunkName: "Main" */"@/components/Main");
const Favorites = () => import(/* webpackChunkName: "Favorites" */"@/views/Favorites");
const PageNotFound = () => import(/* webpackChunkName: "404" */"@/components/PageNotFound");
const Pdf = () => import(/* webpackChunkName: "PDF" */"@/components/Pdf");
const Instructions = () => import(/* webpackChunkName: "Instructions" */"@/views/Instructions");
const AboutView = () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
Vue.use(VueRouter);

const routes = [
  {
    path: "/error-404",
    name: "error-404",
    component: PageNotFound,
  },
  { path: "*", redirect: "error-404" },
  {
    path: "/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:token/:projectToken?/pdf/:id",
    name: "Pdf",
    component: Pdf,
  },
  {
    path: "/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:token/:projectToken?/instructions/",
    name: "Instrutions",
    component: Instructions,
  },
  {
    path: "/:lang(us|ru|en|he|ka|mne|zh|pt|tr|uz|cs|uk|de|ar)?/:token/:projectToken?",
    name: "Main",
    component: Main,
    children: [
      {
        path: "projects/",
        name: "Projects",
        component: PageProjects,
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
              to: { name: "Projects" },
              active: true,
            },
          ],
        },
      },
      {
        path: "info/",
        name: "Info",
        component: PageInfo,
        meta: {
          pageTitle: "Info",
          breadcrumb: [
            {
              text: "Info",
              to: { name: "Info" },
              active: true,
            },
          ],
        },
      },
      {
        path: "panorama/",
        name: "Panorama",
        component: PagePanorama,
        meta: {
          pageTitle: "Panorama",
          breadcrumb: [
            {
              text: "Panorama",
              to: { name: "Panorama" },
              active: true,
            },
          ],
        },
      },
      {
        path: "group-interactive/",
        name: "InteractiveWithGroup",
        component: PageInteractive,
        meta: {
          pageTitle: "Group Interactive",
          breadcrumb: [
            {
              text: "Group Interactive",
              to: { name: "Interactive", params: { reset: true } },
              active: true,
            },
          ],
        },
      },
      {
        path: "interactive/",
        name: "Interactive",
        component: PageInteractive,
        meta: {
          pageTitle: "Interactive",
          breadcrumb: [
            {
              text: "Interactive",
              to: { name: "Interactive", params: { reset: true } },
              active: true,
            },
          ],
        },
      },
      {
        path: "interactive/estate/:estateId",
        name: "InteractiveWithEstate",
        component: PageInteractive,
        meta: {
          withEstate: true,
          pageTitle: "Estate Interactive",
          breadcrumb: [
            {
              text: "Interactive",
              to: { name: "Interactive", params: { reset: true } },
            },
            {
              text: "Estate Interactive",
              to: { name: "InteractiveWithEstate" },
              active: true,
              type: "estateId",
            },
          ],
        },
      },
      {
        path: "interactive/estate/:estateId/section/:sectionId",
        name: "InteractiveWithSection",
        component: PageInteractive,
        meta: {
          withEstate: true,
          pageTitle: "Section Interactive",
          breadcrumb: [
            {
              text: "Interactive",
              to: { name: "Interactive", params: { reset: true } },
            },
            {
              text: "Estate Interactive",
              to: { name: "InteractiveWithEstate" },
              type: "estateId",
            },
            {
              text: "Section Interactive",
              to: { name: "InteractiveWithSection" },
              active: true,
              type: "sectionId",
            },
          ],
        },
      },
      {
        path: "interactive/estate/:estateId/section/:sectionId/storey-sidebar/:storeyId",
        name: "InteractiveWithSectionSidebar",
        component: PageInteractive,
        meta: {
          withEstate: true,
          pageTitle: "Section Interactive",
          breadcrumb: [
            {
              text: "Interactive",
              to: { name: "Interactive", params: { reset: true } },
            },
            {
              text: "Estate Interactive",
              to: { name: "InteractiveWithEstate" },
              type: "estateId",
            },
            {
              text: "Section Interactive",
              to: { name: "InteractiveWithSection" },
              type: "sectionId",
            },
            {
              text: "Storey Sidebar",
              to: { name: "InteractiveWithSectionSidebar" },
              type: "storeyId",
              active: true,
            },
          ],
        },
      },
      {
        path: "interactive/estate/:estateId/section/:sectionId/storey-sidebar/:storeyId/instance/:instanceId",
        name: "InteractiveWithSectionSidebarInstance",
        component: PageInteractive,
        meta: {
          withEstate: true,
          pageTitle: "Section Interactive",
          breadcrumb: [
            {
              text: "Interactive",
              to: { name: "Interactive", params: { reset: true } },
            },
            {
              text: "Estate Interactive",
              to: { name: "InteractiveWithEstate" },
              type: "estateId",
            },
            {
              text: "Section Interactive",
              to: { name: "InteractiveWithSection" },
              type: "sectionId",
            },
            {
              text: "Storey Sidebar",
              to: { name: "InteractiveWithSectionSidebar" },
              type: "storeyId",
              active: true,
            },
          ],
        },
      },
      {
        path: "interactive/estate/:estateId/section/:sectionId/storey/:storeyId",
        name: "InteractiveWithStorey",
        component: PageInteractive,
        meta: {
          withEstate: true,
          pageTitle: "Section Interactive",
          breadcrumb: [
            {
              text: "Interactive",
              to: { name: "Interactive", params: { reset: true } },
            },
            {
              text: "Estate Interactive",
              to: { name: "InteractiveWithEstate" },
              type: "estateId",
            },
            {
              text: "Section Interactive",
              to: { name: "InteractiveWithSection" },
              type: "sectionId",
            },
            {
              text: "Storey Interactive",
              to: { name: "InteractiveWithStorey" },
              active: true,
              type: "storeyId",
            },
          ],
        },
      },
      {
        path: "interactive/estate/:estateId/section/:sectionId/storey/:storeyId/instance/:instanceId",
        name: "InteractiveWithInstance",
        component: PageInteractive,
        meta: {
          withEstate: true,
          pageTitle: "Section Interactive",
          breadcrumb: [
            {
              text: "Interactive",
              to: { name: "Interactive", params: { reset: true } },
            },
            {
              text: "Estate Interactive",
              to: { name: "InteractiveWithEstate" },
              type: "estateId",
            },
            {
              text: "Section Interactive",
              to: { name: "InteractiveWithSection" },
              type: "sectionId",
            },
            {
              text: "Storey Interactive",
              to: { name: "InteractiveWithStorey" },
              active: true,
              type: "storeyId",
            },
          ],
        },
      },
      {
        path: "premises",
        name: "Premises",
        component: PagePremises,
        meta: {
          pageTitle: "Premises",
          breadcrumb: [
            {
              text: "Premises",
              to: { name: "Premises" },
              active: true,
            },
          ],
        },
      },
      {
        path: "premises/:instanceId",
        name: "PremisesWithInstance",
        component: PagePremises,
        meta: {
          pageTitle: "Premises",
          breadcrumb: [
            {
              text: "Premises",
              to: { name: "Premises" },
            },
          ],
        },
      },
      {
        path: "chess/",
        name: "Chess",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chess/:instanceId",
        name: "ChessWithInstance",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chess/storey-sidebar/:storeyId",
        name: "ChessWithStoreySidebar",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chess/storey-sidebar/:storeyId/instance/:instanceId",
        name: "ChessWithStoreySidebarInstance",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chess/estate/:estateId",
        name: "ChessWithEstate",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              // text: 'Estate',
              to: { name: "ChessWithEstate" },
              active: true,
              type: "estateId",
            },
          ],
        },
      },
      {
        path: "chess/estate/:estateId/storey-sidebar/:storeyId",
        name: "ChessWithEstateWithStoreySidebar",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              // text: 'Estate',
              to: { name: "ChessWithEstate" },
              active: true,
              type: "estateId",
            },
          ],
        },
      },
      {
        path: "chess/estate/:estateId/storey-sidebar/:storeyId/instance/:instanceId",
        name: "ChessWithEstateWithStoreySidebarInstance",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
            {
              // text: 'Estate',
              to: { name: "ChessWithEstate" },
              active: true,
              type: "estateId",
            },
          ],
        },
      },
      {
        path: "chess/storey-sidebar/:storeyId/instance/:instanceId",
        name: "ChessWithStoreySidebarInstance",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
            {
              // text: 'Estate',
              to: { name: "ChessWithEstate" },
              active: true,
              type: "estateId",
            },
          ],
        },
      },
      {
        path: "chess/estate/:estateId/instance/:instanceId",
        name: "ChessWithEstateWithInstance",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              // text: 'Estate',
              to: { name: "ChessWithEstate" },
              active: true,
              type: "estateId",
            },
          ],
        },
      },

      {
        path: "chessPlus",
        name: "ChessPlus",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "ChessPlus",
          breadcrumb: [
            {
              text: "Chess Plus",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              text: "Estate",
              type: "estateId",
              to: { name: "ChessPlusWithEstate" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chessPlus/storey-sidebar/:storeyId",
        name: "ChessPlusWithStoreySidebar",
        component: PageChess,
        meta: {
          pageTitle: "ChessPlus",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chessPlus/storey-sidebar/:storeyId/instance/:instanceId",
        name: "ChessPlusWithStoreySidebarInstance",
        component: PageChess,
        meta: {
          pageTitle: "ChessPlus",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chessPlus/:instanceId",
        name: "ChessPlusWithInstance",
        component: PageChess,
        meta: {
          pageTitle: "Chess",
          breadcrumb: [
            {
              text: "Chess",
              to: { name: "Chess" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chessPlus/estate/:estateId",
        name: "ChessPlusWithEstate",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "ChessPlus",
          breadcrumb: [
            {
              text: "Chess Plus",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              text: "Estate",
              type: "estateId",
              to: { name: "ChessPlusWithEstate" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chessPlus/estate/:estateId/instance/:instanceId",
        name: "ChessPlusWithEstateWithInstance",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "ChessPlus",
          breadcrumb: [
            {
              text: "Chess Plus",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              text: "Estate",
              type: "estateId",
              to: { name: "ChessPlusWithEstate" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chessPlus/estate/:estateId/storey-sidebar/:storeyId",
        name: "ChessPlusWithEstateWithStoreySidebar",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "ChessPlus",
          breadcrumb: [
            {
              text: "Chess Plus",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              text: "Estate",
              type: "estateId",
              to: { name: "ChessPlusWithEstate" },
              active: true,
            },
          ],
        },
      },
      {
        path: "chessPlus/estate/:estateId/storey-sidebar/:storeyId/instance/:instanceId",
        name: "ChessPlusWithEstateWithStoreySidebarInstance",
        component: PageChess,
        meta: {
          withEstate: true,
          pageTitle: "ChessPlus",
          breadcrumb: [
            {
              text: "Chess Plus",
              to: { name: "Chess", params: { reset: true } },
            },
            {
              text: "Estate",
              type: "estateId",
              to: { name: "ChessPlusWithEstate" },
              active: true,
            },
          ],
        },
      },
      // {
      //   path: "info",
      //   name: "Info",
      //   component: PageInfo,
      //   meta: {
      //     pageTitle: "Info",
      //     breadcrumb: [
      //       {
      //         text: "Info",
      //         to: { name: "Info" },
      //         active: true,
      //       },
      //     ],
      //   },
      // },
      {
        path: "favorites",
        name: "Favorites",
        component: Favorites,
        meta: {
          pageTitle: "Favorites",
          breadcrumb: [
            {
              text: "Favorites",
              to: { name: "Favorites" },
              active: true,
            },
          ],
        },
      },
      {
        path: "favorites/:instanceId",
        name: "FavoritesWithInstance",
        component: Favorites,
        meta: {
          pageTitle: "Favorites",
          breadcrumb: [
            {
              text: "Favorites",
              to: { name: "Favorites" },
            },
          ],
        },
      },

      // {
      //   path: "home",
      //   name: "home",
      //   component: HomeView,
      // },
      {
        path: "about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: AboutView,
      }
      // { path: "*", component: PageNotFound }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  // Get the ref value from the store or wherever it is stored
  const ref = store.state.ref;
  if (ref !== null) {
    window.parent.postMessage({
      type: 'changeRPTModulePage',
      data: to.fullPath,
    },ref);
  }
});

router.beforeEach((to, from, next) => {
  // console.log(from !== to)
  // console.log(from)
  // console.log(to)
  next()
  // if (!to.params.lang && from.params.lang) {
  //   const lang = from.params.lang;
  //   const toWithLang = {
  //     ...to,
  //     params: {
  //       ...to.params,
  //       lang,
  //     },
  //   };
  //   console.log(toWithLang)
  //   next(toWithLang);
  // } else {
  //   console.log('123')
  //   next();
  // }
  // if (!to.params.lang && from.params.lang ) {
  //   return next({
  //     ...to,
  //     params: {
  //       ...to.params,
  //       lang: from.params.lang,
  //     },
  //   });
  // }
  //
  // return next();
  //
  // const optionalParam = from.params.lang;
  // if (optionalParam) {
  //   to.params.lang = optionalParam; // Set the prop value to the route parameter value
  // }
  // console.log(from.params.lang)
  // console.log(to.params.lang)
  // next({params: {lang: from.params.lang}});

  // return next();


  // if (
  //   from.meta &&
  //   from.meta.withEstate &&
  //   to?.params?.reset !== true &&
  //   to.name.includes("Chess")
  // ) {
  //   next({
  //     name: to.name + "WithEstate",
  //     params: { estateId: from.params.estateId },
  //   });
  // }

  // return next();
});

export default router;

import axios from "axios";
import i18n from "@/libs/i18n";


export default {
    state: () => ({
        // interactive
        project_group_interactive: null,
        project_interactive: null,
        estate_interactive: null,
        section_interactive: null,
        storey_layout_interactive: null,
        interactiveTranslate: 0,
        // tooltips
        tooltip_info: null,
    }),
    getters: {
        tooltip_info(state) {
            return state.tooltip_info;
        },
        interactiveTranslate(state){
            return state.interactiveTranslate
        },
        project_group_interactive(state,getters,rootState,rootGetters) {
            let project_group_interactive = state.project_group_interactive;
            if(project_group_interactive?.projects && rootGetters.projectGroup) {
                project_group_interactive.projects = project_group_interactive.projects.filter(project => {
                    return rootGetters.projectGroup.projects?.find(i => i.id == project.id) ? true : false
                })
            }
            return project_group_interactive;
        },
        project_interactive(state,getters,rootState,rootGetters) {
            let project_interactive = state.project_interactive;
            // if(project_interactive?.estates) {
            //     project_interactive.estates = project_interactive.estates.filter(estate => {
            //         return rootGetters.estates?.find(i => i.id == estate.id) ? true : false;
            //     })
            // }
            return project_interactive
        },
        estate_interactive(state) {
            return state.estate_interactive;
        },
        section_interactive(state) {
            return state.section_interactive;
        },
        storey_layout_interactive(state) {
            return state.storey_layout_interactive;
        },
    },
    mutations: {
        SET_TOOLTIP_INFO(state, data) {
            state.tooltip_info = data;
        },
        SET_PROJECT_GROUP_INTERACTIVE_TO_STATE: (state, response) => {
            state.project_group_interactive = response;
        },
        SET_PROJECT_INTERACTIVE_TO_STATE: (state, response) => {
            state.project_interactive = response;
        },
        SET_ESTATE_INTERACTIVE_TO_STATE: (state, response) => {
            state.estate_interactive = response;
        },
        SET_SECTIONS_INTERACTIVE_TO_STATE: (state, response) => {
            state.section_interactive = response;
        },
        SET_STOREYS_INTERACTIVE_TO_STATE: (state, response) => {
            state.storey_layout_interactive = response;
        },

    },
    actions: {
        GET_PROJECT_TOOLTIP_INFO(ctx,item) {
            if (item) {
                let data = ctx.rootGetters?.projectGroup?.projects?.find((project) => project.id === item.id);
                data.type = "project";
                ctx.commit("SET_TOOLTIP_INFO", data);
            } else {
                ctx.commit("SET_TOOLTIP_INFO", null);
            }
        },
        GET_ESTATE_TOOLTIP_INFO(ctx, item) {
            if (item) {
                let data = ctx.rootGetters.estates.find((estate) => estate.id === item.id);
                data.type = "estate";
                ctx.commit("SET_TOOLTIP_INFO", data);
            } else {
                ctx.commit("SET_TOOLTIP_INFO", null);
            }
        },
        GET_SECTION_TOOLTIP_INFO(ctx, item) {
            // console.log(item,'section')
            if (item) {
                let data = ctx.rootGetters.sections?.find(
                    (section) => section.id === item.id
                );
                if (data) {
                    data.type = "section";
                    data.name = i18n.t("titles.Section") + " "+ i18n.t("titles.Number symbol") + data.number;
                    ctx.commit("SET_TOOLTIP_INFO", data);
                }
            } else {
                ctx.commit("SET_TOOLTIP_INFO", null);
            }
        },
        GET_STOREY_TOOLTIP_INFO(ctx, item) {
            if (item) {
                let data = ctx.rootGetters?.storeys?.find(
                    (storey) => storey.id === item.id
                );
                if (data) {
                    data.name = i18n.t("titles.Storey") + " "+ i18n.t("titles.Number symbol") + data.number;
                    data.type = "storey";
                    ctx.commit("SET_TOOLTIP_INFO", data);
                }
            } else {
                ctx.commit("SET_TOOLTIP_INFO", null);
            }
        },
        GET_INSTANCE_TOOLTIP_INFO(ctx, item) {
            if (item) {
                let data = ctx.rootGetters.instances.find((i) => i.id == item.id);
                data.name = i18n.t("titles.Number symbol") + data.number;
                data.type = "instance";
                ctx.commit("SET_TOOLTIP_INFO", data);
            } else {
                ctx.commit("SET_TOOLTIP_INFO", null);
            }
        },
        UNSET_INTERACTIVE(ctx) {
            // ctx.commit(
            //       "SET_STOREYS_INTERACTIVE_TO_STATE",
            //       null
            // );
            ctx.commit(
                "SET_SECTIONS_INTERACTIVE_TO_STATE",
                null
            );
            ctx.commit(
                "SET_ESTATE_INTERACTIVE_TO_STATE",
                null
            );
            ctx.commit(
                "SET_PROJECT_INTERACTIVE_TO_STATE",
                null
            );
        },


        async GET_PROJECT_GROUP_INTERACTIVE(ctx, id) {
            ctx.dispatch('LOAD_START','GET_PROJECT_GROUP_INTERACTIVE')

            await ctx.dispatch('axiosWrapped',{
                url : `public/project-groups/${ctx.rootState?.group_id}/interactive`,
            })
                .then(function (response) {
                    ctx.commit("SET_PROJECT_GROUP_INTERACTIVE_TO_STATE", response.data.data);
                    console.log("get project group interactive finish");
                })
                .catch(function (error) {
                    console.log(error, "error in fetching project interactive");
                })
                .then(function (){
                    ctx.dispatch('LOAD_END','GET_PROJECT_GROUP_INTERACTIVE')
                });
        },
        async GET_PROJECT_INTERACTIVE(ctx, id = 0) {
            ctx.dispatch('LOAD_START','GET_PROJECT_INTERACTIVE')

            // console.log('get project interactive')
            await ctx.dispatch('axiosWrapped',{
                url : `public/projects/${id}/interactive`,
            })
                .then(function (response) {
                    // console.log(response.data.data);
                    ctx.commit("SET_PROJECT_INTERACTIVE_TO_STATE", response.data.data);
                    console.log("get project interactive finish");
                })
                .catch(function (error) {
                    console.log(error, "error in fetching project interactive");
                })
                .then(function (){
                    ctx.dispatch('LOAD_END','GET_PROJECT_INTERACTIVE')
                });
        },
        async GET_ESTATE_INTERACTIVE(ctx, id) {
            await ctx.dispatch('axiosWrapped',{
                url : `public/estates/${id}/interactive`,
            })
                .then(function (response) {
                    ctx.commit(
                        "SET_ESTATE_INTERACTIVE_TO_STATE",
                        response.data.data
                    );
                    console.log("get estate interactive finish");
                })
                .catch(function (error) {
                    console.log(error, "error in fetching estate interactive");
                });
        },
        async GET_SECTIONS_INTERACTIVE(ctx, id) {
            await ctx.dispatch('axiosWrapped',{
                url : `public/sections/${id}/interactive`,
            })
                .then(function (response) {
                    ctx.commit("SET_SECTIONS_INTERACTIVE_TO_STATE", response.data.data);
                    console.log("get sections interactive finish");
                })
                .catch(function (error) {
                    console.log(error, "error in fetching sections interactive");
                });
        },
        async GET_STOREYS_INTERACTIVE(ctx, id) {
            await ctx.dispatch('axiosWrapped',{
                url : `public/storeys/${id}/interactive`,
            })
                .then(function (response) {
                    ctx.commit(
                        "SET_STOREYS_INTERACTIVE_TO_STATE",
                        response.data.data
                    );
                    console.log("get storey interactive finish");
                })
                .catch(function (error) {
                    console.log(error, "error in fetching storey interactive");
                });
        },

    }
}
import router from "@/router/index";
import store from "@/store";

export default {
    methods: {
        push(push) {
            // console.log(push);
            let lang;
            lang = store.state.lang?.name
            // console.log(lang)

            let params = {
                lang:lang,
                projectToken: store.state.projectToken
            };

            if(store.state.projectToken){
                if(!push.params){
                    push.params = {}
                }
                push.params.projectToken = store.state.projectToken;
            }

            if(lang == store.state?.tokenSettings?.lang.name){
                delete params.lang
                lang = undefined
            }

            if(
                this.compareParams(push, lang)
            ){
                // console.log("REDUNDAT NAV");
                return
            }
            // if(store.state.lang.id === store.state.tokenSettings.lang.id){
            //     delete push.params.lang;
            //
            // }
            // console.clear()
            // console.log(push.params)
            // console.log(params)

            if(push?.replace == true){
                // console.log('push replaced')
                router.replace({...push, params: {...push.params, ...params}})
            }
            else{
                // console.log('push not replaced')
                router.push({...push, params: {...push.params, ...params}}).then(() => {
                    if(window.RPTAnalytics){
                        // console.log(window.location.href)
                        // console.log(RPTAnalytics)
                        RPTAnalytics.visit(window.location.href)
                    }
                })
            }
        },
        compareParams(push,lang){
            if(
                this.compareObjects(push,router.currentRoute) &&
                router.currentRoute.params?.lang == lang
            )
            {
                return true
            } else false
        },
        compareObjects(obj1, obj2) {
            for (const key in obj1) {
                if (!(key in obj2)) {
                    return false;
                }
                const val1 = obj1[key];
                const val2 = obj2[key];
                if (typeof val1 === 'object' && typeof val2 === 'object') {
                    if (!this.compareObjects(val1, val2)) {
                        return false;
                    }
                } else if (val1 !== val2) {
                    return false;
                }
            }
            return true;
        }
    }
}